import { AxiosResponse, CancelToken, AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';
import { ReportJsonResponse } from '~/types/report/reportJsonResponse';
import { ITasksService } from './tasks-service.interfaces';
import { ReportStatusModel } from '~/types/report/status';
import { ReportResponse } from '~/types/report/reportResponse';
import { IApiEndpointAuthServiceKey } from '~/endpoints/services/apiEndpointAuthService.interface';

@injectable()
export class TasksService implements ITasksService {
  private readonly BASE_URL_V2 = '/api/v2/async-pdf/tasks';

  constructor(@inject(IApiEndpointAuthServiceKey as symbol) private api: AxiosInstance) {}
  
  getTaskJson(reportTaskId: string): Promise<AxiosResponse<ReportJsonResponse, any>> {
    return this.api.get(`${this.BASE_URL_V2}/${reportTaskId}/json`);
  }

  getTaskResult(reportTaskId: string): Promise<AxiosResponse<Blob, any>> {
    return this.api.get(`${this.BASE_URL_V2}/${reportTaskId}/result`, {
      responseType: 'blob',
    });
  }

  getTaskStatus(reportTaskId: string): Promise<AxiosResponse<ReportStatusModel, any>> {
    return this.api.get<ReportStatusModel>(`${this.BASE_URL_V2}/${reportTaskId}/status`);
  }

  getTasks(pageSize: number, pageNum: number, cancelToken?: CancelToken | undefined): Promise<AxiosResponse<ReportResponse, any>> {
    return this.api.get<ReportResponse>(`${this.BASE_URL_V2}`, {
      cancelToken,
      params: {
        pageSize,
        pageNum,
      },
    });
  }
}
