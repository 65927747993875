import { injectable } from 'inversify';
import { IAuthXApiStrategyService } from '~/endpoints/services/authStrategyService.interfaces';
import { appSettings } from '~/settings';
import { AuthStrategy } from '~/endpoints/types/AuthStrategy';

@injectable()
export class AuthXApiStrategyService implements IAuthXApiStrategyService {
  getAuthHeadersProperty(): AuthStrategy {
    return {
      XApiKey: appSettings.token
    };
  }
}
