export enum ReportNavLinksType {
  Unknown = 0,
  Card = 1, // 'Карточка'
  RegistrationData = 2, // 'Регистрационные данные'
  CompanyStruct = 3, // 'Структура компании'
  ChangeHistory = 4, // 'История изменений'
  CompanyActivities = 5, // 'Деятельность компании'
  Employees = 6, // 'Сотрудники'
  Property = 7, // 'Имущество'
  FinancialAnalytics = 8, // 'Финансовая аналитика'
  Connections = 9, // 'Связи'
  ContactsInfo = 10, // 'Контактные данные'
  GeneralInformation = 11, // 'Общая информация'
  Family = 12, // 'Cемья'
  LifePath = 13, // 'Жизненный путь'
  ScoringScores = 14, // 'Скоринговые оценки'
  NegativeInfo = 15, // 'Негативная информация'
  PropertyInfo = 16, // 'Недвижимое имущество'
  MovableProperty = 17, // 'Движимое имущество'
  Advertisement = 18, // 'Объявления'
  Movements = 19, // 'Перемещения'
  BoundedPersonPhones = 20, // 'Связанные абонентские номера'
  SocialNetworks = 21, // 'Информация из социальных сетей'
}
