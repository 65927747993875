import { injectable } from 'inversify';
import { IReportConnectionRelationPersonService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionRelationPersonService.interface';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';
import { ConnectionEntitiesInfo } from '~/modules/reportsCommon/reportConnection/types/connectionEntitiesInfo';

@injectable()
export class ReportConnectionRelationPersonService implements IReportConnectionRelationPersonService {
  getRelationPerson(connections: Array<ConnectionItemInfo>, connectionIdx: number): (entitiesIdx: number) => ConnectionEntitiesInfo {
    const connectionItem = connections[connectionIdx];

    return function (entitiesIdx: number) {
      return connectionItem.Entities[entitiesIdx];
    };
  }
}
