<script lang="ts" setup>
import { appSettings } from '~/settings';
import { WindowUtils } from '@/utils/windowUtils';

import containerAccessor from './configuration/inversify/containerAccessor';
import { getContainer } from '~/configuration/inversify/container';

import { useNodesHiding } from '~/hooks/useNodesHiding';
import { usePageTitle } from '@/modules/app/composables/usePageTitle';

containerAccessor.updateContainer(getContainer());

const { isPdfView } = useViewModeClass();
const viewModeClass = computed(() => (isPdfView ? 'pdfView' : 'webView'));

const pageTitle = usePageTitle();

// Фиксированое значение viewport стоит пока(если) не появится дизайн для мобилки. Пока адаптивный viewport не нужен
useHead({
  /* title: app.name, */
  titleTemplate: pageTitle,
  meta: [
    {
      name: 'viewport',
      content: 'width=1130, initial-scale=1'
    },
    {
      hid: 'description',
      name: 'description',
      content: 'Sweep net'
    }
  ],
  link: [
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: '/favicon.ico'
    },
    {
      rel: 'apple-touch-icon',
      href: '/favicon.ico',
      type: 'image/x-icon'
    },
    {
      rel: 'apple-touch-icon',
      href: '/favicon.ico',
      type: 'image/x-icon'
    },
    {
      rel: 'mask-icon',
      href: '/favicon.ico',
      type: 'image/x-icon'
    }
  ],
  bodyAttrs: {
    class: viewModeClass
  }
});

useNodesHiding('main', (element) => {
  const styles = window.getComputedStyle(element);
  const display: string = styles.getPropertyValue('display');

  if (display == 'flex' && isPdfView) {
    return ![...element.childNodes].some(
      (node) => node.nodeType != Node.COMMENT_NODE
    );
  }
  return false;
});

onMounted(() => {
  console.log(appSettings);
  (<any>window).__app_initialized = true;

  const updaters = new Array<() => void>();
  (<any>window).__updaters = updaters;
  (<any>window).__update = () => {
    updaters.forEach((updater) => updater());
  };
});

const scrollbarWidth = ref(0);

onMounted(() => {
  scrollbarWidth.value = WindowUtils.getScrollWidth();
});

watch(scrollbarWidth, (newValue) => {
  document.body.style.setProperty('--scrollbarWidth', `${newValue}px`);
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage :class="viewModeClass" />
  </NuxtLayout>
</template>

<style lang="scss">
@page {
  size: A4;
  margin: 80px 0 80px;
}

@page:first {
  margin: 60px 0 80px;
}

@media print {
  html,
  body {
    width: 1240px;
    height: 1470px;
  }

  html {
    -webkit-print-color-adjust: exact;
    // Установка прозрачности элемента для растеризации в PDF
    //-webkit-filter: opacity(1);
  }
}
</style>
