import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ReportBoundedPhonesConfigService } from '../../common/services/reportBoundedPhonesConfigService';
import { IReportBoundedPhonesConfigServiceKey } from '../../common/services/reportBoundedPhonesConfigService.interface';
import { ReportBoundedPhonesMergeService } from '../../common/services/reportBoundedPhonesMergeService';
import { IReportBoundedPhonesMergeServiceKey } from '../../common/services/reportBoundedPhonesMergeService.interfaces';
import { IndividualBoundedPhonesNumberConfigService } from '../../individual/services/individualBoundedPhonesNumberConfigService';
import { IIndividualBoundedPhonesNumberConfigServiceKey } from '../../individual/services/individualBoundedPhonesNumberConfigService.interface';
import { IndividualBoundedPhonesRelationsService } from '../../individual/services/individualBoundedPhonesRelationsService';
import { IIndividualBoundedPhonesRelationsServiceKey } from '../../individual/services/individualBoundedPhonesRelationsService.interface';
import { IndividualBoundedPhoneSiftingService } from '~/modules/reportsCommon/reportBoundedPhones/individual/services/individualBoundedPhoneSiftingService';
import { IIndividualBoundedPhoneSiftingServiceKey } from '~/modules/reportsCommon/reportBoundedPhones/individual/services/individualBoundedPhoneSiftingService.interfaces';

export function bindReportBoundedPhonesService(bind: IContainerBinder) {
  bind(IReportBoundedPhonesConfigServiceKey).to(ReportBoundedPhonesConfigService);
  bind(IReportBoundedPhonesMergeServiceKey).to(ReportBoundedPhonesMergeService);
  bind(IIndividualBoundedPhonesRelationsServiceKey).to(IndividualBoundedPhonesRelationsService);
  bind(IIndividualBoundedPhonesNumberConfigServiceKey).to(IndividualBoundedPhonesNumberConfigService);
  
  bind(IIndividualBoundedPhoneSiftingServiceKey).to(IndividualBoundedPhoneSiftingService);
}
