import { injectable } from 'inversify';
import { IAuthBearerStrategyService } from '~/endpoints/services/authStrategyService.interfaces';
import { AuthStrategy } from '~/endpoints/types/AuthStrategy';

@injectable()
export class AuthBearerStrategyService implements IAuthBearerStrategyService {
  getAuthHeadersProperty():AuthStrategy {
    return {
      Authorization: `Bearer ${localStorage.accessToken ?? ''}`
    };
  }
}
