import { MergeService } from '~/services/merge/merge-service';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import { StringUtils } from '~/utils/stringUtils';

export class LegalEntitiesParticipationMergeService extends MergeService<ReportLegalEntitiesParticipationDTO> {
  protected canMerge(leftItem: ReportLegalEntitiesParticipationDTO, rightItem: ReportLegalEntitiesParticipationDTO): boolean {
    return leftItem.Ogrn === rightItem.Ogrn;
  }

  protected mergeFields(leftItem: ReportLegalEntitiesParticipationDTO, rightItem: ReportLegalEntitiesParticipationDTO): ReportLegalEntitiesParticipationDTO {
    const isParticipationTypeHistoric = leftItem.ParticipationType.includes('бывший');
    
    let participationType = '';

    if (StringUtils.isStringNotEmpty(leftItem.ParticipationType)) {
      participationType = leftItem.ParticipationType;
    }

    if (StringUtils.isStringNotEmpty(rightItem.ParticipationType)) {
      participationType = isParticipationTypeHistoric ? `${rightItem.ParticipationType}, ${participationType}` : `${participationType}, ${rightItem.ParticipationType}`;
    }

    return {
      Address: leftItem.Address ?? rightItem.Address,
      AuthorizedCapitalSum: leftItem.AuthorizedCapitalSum ?? rightItem.AuthorizedCapitalSum,
      CompanyName: leftItem.CompanyName ?? rightItem.CompanyName,
      Inn: leftItem.Inn ?? rightItem.Inn,
      IsHistoric: leftItem.IsHistoric ?? rightItem.IsHistoric,
      LiquidationDate: leftItem.LiquidationDate ?? rightItem.LiquidationDate,
      Ogrn: leftItem.Ogrn ?? rightItem.Ogrn,
      ParticipationType: StringUtils.CapitalizeString(participationType.toLowerCase()),
      CapitalSharesPercent: leftItem.CapitalSharesPercent ?? rightItem.CapitalSharesPercent,
      Position: leftItem.Position ?? rightItem.Position,
      PrincipalActivity: leftItem.PrincipalActivity ?? rightItem.PrincipalActivity,
      RegistrationDate: leftItem.RegistrationDate ?? rightItem.RegistrationDate,
      RiskIndicator: leftItem.RiskIndicator ?? rightItem.RiskIndicator,
      Shortname: leftItem.Shortname ?? rightItem.Shortname,
      Status: leftItem.Status ?? rightItem.Status,
    };
  }
}
