import { isNumber, isString } from 'lodash-es';
import { Config } from '../types/config';
import { COLUMN, DIVIDER_ROW } from '../constants/direction';
import { inject, injectable } from 'inversify';
import { IFieldListGuard, IFieldListGuardKey } from './fieldListGuard.interfaces';
import { FieldLabel } from '../types/field-label';
import { FieldMargin } from '../types/field-margin';
import { Field } from '../types/field';
import { IFieldListMapper } from './fieldListMapper.interfaces';
import { Direction } from '../types/direction';

type MappedFieldAttrs = Field & MappedField;

export interface MappedField { 
  labelText: string,
  labelWidth?: string,
  labelDivider: boolean,
  valueText: string,
  valueWidth?: string,
  width?: string,
  rowGap?: string,
  columnGap?: string,
  marginTop?: string,
  marginBottom?: string,
  marginLeft?: string,
  marginRight?: string,
  key: string;
  attrs: MappedFieldAttrs;
  direction: Direction;
  isBeginningDivider: boolean;
}

@injectable()
export class FieldListMapper implements IFieldListMapper {
  constructor(
    @inject(IFieldListGuardKey as symbol) private fieldListGuard: IFieldListGuard
  ) {}

  mapFields(fields: Array<Field>, config: Config): Array<MappedField> {
    return fields.map((field): MappedField => {
      const labelText = this.getLabelText(field.label);
      const labelWidth = this.getLabelWidth(field.label) ?? config.fieldsLabelWidth;
      const columnGap = field.columnGap ?? config.columnGap;
      const labelDivider = (field.direction === DIVIDER_ROW || config.fieldsDirection == DIVIDER_ROW);
      const marginBottom = this.getMargin(field.margin, 'bottom');
      const marginTop = this.getMargin(field.margin, 'top');
      const marginRight = this.getMargin(field.margin, 'right');
      const marginLeft = this.getMargin(field.margin, 'left');
      const rowGap = field.rowGap ?? config.rowGap;
      const valueText = this.getValueText(field.value);
      const valueWidth = this.getValueWidth(field.value) ?? config.fieldsValueWidth;
      const width = field.width ?? config.fieldsWidth;
      const key = field.key;
      const direction = field.direction ?? config.fieldsDirection ?? COLUMN;
      const isBeginningDivider = config.isBeginningDivider ?? false;

      const mappedField = {
        columnGap,
        labelDivider,
        labelText,
        labelWidth,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        rowGap,
        valueText,
        valueWidth,
        width,
        key,
        direction,
        isBeginningDivider
      }

      return {
        ...mappedField,
        attrs: { ...field, ...mappedField } as MappedFieldAttrs
      }
  })};
  
  private getMargin(margin: FieldMargin | undefined | string, marginDirection: keyof FieldMargin): string | undefined {
    return isString(margin) ? margin : margin?.[marginDirection];
  }

  private getLabelText(label: FieldLabel): string {
    if (isString(label)) return label;
    return label.text
  }

  private getLabelWidth(label: FieldLabel): string | undefined {
    if (isString(label)) return undefined;
    return label.width;
  }

  private getValueWidth(value: unknown) {
    if (this.fieldListGuard.isFieldValueObject(value)) {
      return value.width;
    }
  }

  private getValueText(value: unknown): string {
    if (this.fieldListGuard.isFieldValueObject(value)) {
      if (isString(value.text) || isNumber(value.text)) {
        return `${value.text}`;
      }
  
      return '';
    }
  
    if (isString(value) || isNumber(value)) {
      return `${value}`;
    }
  
    return '';
  }
}