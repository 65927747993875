import { orderBy } from 'lodash-es';
import { injectable } from 'inversify';
import { IReportJobsSiftingService } from '~/modules/reportsCommon/reportJobs/services/reportJobsSiftingService.interface';
import { ReportJobDTO } from '~/types/dto/common/reportJobDTO';

@injectable()
export class ReportJobsSiftingService implements IReportJobsSiftingService {
  getSiftedData(initData: Array<ReportJobDTO>): Array<ReportJobDTO> {
    return orderBy(initData, ['StartDate'], ['desc'])
      .filter((item) => item.CompanyName);
  }
}
