import { ReportMessageBoardsType } from '~/modules/reportsCommon/reportMessageBoardRecord/types/reportMessageBoardsType';
import { URLUtils } from '~/utils/url';
import Auto from '~/modules/svg/social/Auto.vue';
import Drom from '~/modules/svg/social/Drom.vue';
import Avito from '~/modules/svg/social/Avito.vue';
import Rolf from '~/modules/svg/social/Rolf.vue';
import Youla from '~/modules/svg/social/Youla.vue';
import Default from '~/modules/svg/social/Default.vue';
import { SvgComponentsOptions } from '~/modules/svg/types/svgComponentsOptions';

export module MessageBoardsUtils {
  export function formatMessageBoardsType(type: ReportMessageBoardsType) {
    switch (type) {
      case ReportMessageBoardsType.Auto:
        return 'Auto';
      case ReportMessageBoardsType.Drom:
        return 'Drom';
      case ReportMessageBoardsType.Avito:
        return 'Avito';
      case ReportMessageBoardsType.Rolf:
        return 'Rolf';
      case ReportMessageBoardsType.Youla:
        return 'Youla';
      default:
        return '';
    }
  }

  export function getMessageBoardsIconByType(type: ReportMessageBoardsType): SvgComponentsOptions {
    const widthAndHeight = {
      width: '24',
      height: '24'
    };
    switch (type) {
      case ReportMessageBoardsType.Auto:
        return {
          icon: markRaw(Auto),
          width: '70',
          height: '20'
        };
      case ReportMessageBoardsType.Drom:
        return {
          icon: markRaw(Drom),
          ...widthAndHeight
        };
      case ReportMessageBoardsType.Avito:
        return {
          icon: markRaw(Avito),
          ...widthAndHeight
        };
      case ReportMessageBoardsType.Rolf:
        return {
          icon: markRaw(Rolf),
          width: '70',
          height: '24'
        };
      case ReportMessageBoardsType.Youla:
        return {
          icon: markRaw(Youla),
          ...widthAndHeight
        };
      default:
        return {
          icon: markRaw(Default),
          ...widthAndHeight
        };
    }
  }

  export function getMessageBoardsTypeFromUrl(url: string) {
    let newUrl = url;

    if (URLUtils.isUrl(newUrl)) {
      newUrl = URLUtils.getHostName(newUrl);
    }

    switch (newUrl) {
      case 'auto.ru': return ReportMessageBoardsType.Auto;
      case 'avito':
      case 'avito.ru':
        return ReportMessageBoardsType.Avito;
      case 'drom.ru': return ReportMessageBoardsType.Drom;
      case 'rolf-probeg.ru':
      case 'rolf.ru':
        return ReportMessageBoardsType.Rolf;
      case 'youla.ru':
      case 'youla.io': 
      case 't.me': return ReportMessageBoardsType.Youla;
      default: return ReportMessageBoardsType.Unknown;
    }
  }
}
