import { injectable } from 'inversify';
import { IReportPersonSnilsService } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsService.interface';
import { DateUtils } from '~/utils/date';

@injectable()
export class ReportPersonSnilsService implements IReportPersonSnilsService {
  formattingDateInString(value: string): string {
    const regexp = /,/g;

    const formatString = value.replace(regexp, ', ');

    const splitValue = formatString.split(' ');

    return splitValue.map((item) => {
      const date = Date.parse(item.replace(regexp, ''));

      if (!Number.isNaN(date)) {
        return DateUtils.formatDate(item.replace(regexp, ''));
      }

      return item;
    }).join(' ');
  }

  checkVerifySnils(value: string): boolean {
    return value.toLowerCase().includes('не верифицирован');
  }
}
