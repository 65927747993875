import { injectable } from "inversify";
import { ITableRowService } from "./tableRowService.interfaces";
import { TableItem } from "~/types/tableItem";

@injectable()
export class TableRowService implements ITableRowService {
  getItemValue(item: TableItem, headerKey: string, defaultValue = '—'): string {
    if (item[headerKey]) return String(item[headerKey]);

    return defaultValue; 
  }
}