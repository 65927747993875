import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { ReportPersonSnilsConfigServices } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsConfigServices';
import { IReportPersonSnilsConfigServicesKey } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsConfigServices.interfaces';
import { ReportPersonSnilsService } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsService';
import { IReportPersonSnilsServiceKey } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsService.interface';

export function bindReportPersonSnilsServices(bind: IContainerBinder) {
  bind(IReportPersonSnilsConfigServicesKey).to(ReportPersonSnilsConfigServices);

  bind(IReportPersonSnilsServiceKey).to(ReportPersonSnilsService);
}
