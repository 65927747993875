import { injectable, inject } from 'inversify';
import { get } from 'lodash-es';
import { IReportAddressSiftingService } from '~/modules/reportsCommon/reportAddress/services/bindReportAddressSiftingService.interface';
import { ReportAddressDTO } from '~/types/dto/common/addressDTO';
import { HAS_ADDRESS_HOME_REGEX } from '~/regex/hasAddressHome';
import { IReportAddressMergeService, IReportAddressMergeServiceKey } from '~/services/reportAddressMergeService.interfaces';

@injectable()
export class ReportAddressSiftingService implements IReportAddressSiftingService {
  getSiftedData(initData: Array<ReportAddressDTO>): Array<ReportAddressDTO> {
    return initData.filter((address) => {
      const hasHouse = this.checkAddress(address.Address);
      const isDataNoEmpty = this.checkEmptyData(address.Address);

      return hasHouse && isDataNoEmpty;
    });
  }

  private checkAddress(address: string) {
    return HAS_ADDRESS_HOME_REGEX.test(address);
  }

  private checkEmptyData(address: string) {
    const denyData = [' ', ','];
    return !denyData.includes(address);
  }
}
