import { injectable } from 'inversify';
import { ReportLegalEntitiesParticipationDTO } from '~/types/dto/common/reportLegalEntitiesParticipationDTO';
import { ILegalEntitiesParticipationConfigService } from './legalEntitiesParticipationConfigService.interfaces';
import { Config } from '~/types/field-list/config';
import { DateUtils } from '~/utils/date';

@injectable()
export class LegalEntitiesParticipationConfigService implements ILegalEntitiesParticipationConfigService {
  getConfig(initData: ReportLegalEntitiesParticipationDTO): Config {
    const role = this.getRole(initData);
    
    return {
      fieldsDirection: 'divider-row',
      fieldsWidth: '100%',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          label: 'ОГРН',
          key: 'ogrn',
          value: initData.Ogrn
        },
        {
          label: 'ИНН',
          key: 'inn',
          value: initData.Inn
        },
        {
          label: 'Роль',
          key: 'role',
          value: role,
        },
        {
          label: 'Доля в УК, %',
          key: 'CapitalSharesPercent',
          value: initData.CapitalSharesPercent,
        },
        {
          label: 'Дата регистрации',
          key: 'registrationDate',
          value: DateUtils.formatDate(initData.RegistrationDate)
        },
        {
          label: 'Дата прекращения деятельности',
          key: 'liquidationDate',
          value: DateUtils.formatDate(initData.LiquidationDate)
        },
        {
          label: 'Адрес',
          key: 'address',
          value: initData.Address
        },
        {
          label: 'Статус',
          key: 'status',
          value: initData.Status,
        },
        {
          direction: 'column',
          label: 'Основной вид деятельности ОКВЭД',
          key: 'PrincipalActivity',
          value: {
            text: initData.PrincipalActivity,
            width: '100%'
          }
        }
      ]
    };
  }

  private getRole(initData: ReportLegalEntitiesParticipationDTO): string {
    const replaceableRoles = [{ from: 'Генеральный Директор', to: 'Генеральный директор' }]; 

    const role = initData.ParticipationType || initData.Position;

    const replacedRole = replaceableRoles.reduce((finalRole, replaceableRole) => finalRole.replaceAll(replaceableRole.from, replaceableRole.to), role);

    const historicRole = initData.IsHistoric ? `${replacedRole} (бывший)` : replacedRole;
    
    return historicRole;
  }
}
