import axios, { AxiosInstance } from 'axios';
import { injectable } from 'inversify';
import { IInitAxiosService } from '~/endpoints/services/initAxiosService.interface';
import useEnvironment from '~/composables/useEnvironment';
import { Environment } from '~/types/Environment';
import { appSettings } from '~/settings';

@injectable()
export class InitAxiosService implements IInitAxiosService {
  initAxios():AxiosInstance {
    const environment = useEnvironment();
    
    const baseURL = environment == Environment.server
      ? appSettings.dataEndpointInternalURL
      : appSettings.dataEndpointURL;

    return axios.create({
      baseURL
    });
  }
}
