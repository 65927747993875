import { InjectionKey } from 'nuxt/dist/app/compat/capi';
import { Config } from '../types/config';
import { Field } from '../types/field';
import { MappedField } from './fieldListMapper';


export interface IFieldListMapper {
  mapFields(fields: Array<Field>, config: Config): Array<MappedField>;
}

export const IFieldListMapperKey: InjectionKey<IFieldListMapper> = Symbol('IFieldListMapper');
