import { injectable } from 'inversify';
import { get, uniq } from 'lodash-es';
import { IReportConnectionMlValueService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionMlValueService.interface';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';

@injectable()
export class ReportConnectionMlValueService implements IReportConnectionMlValueService {
  getConnectionMlValue(initData: Array<ConnectionItemInfo>): number | undefined {
    const connectionMlValueList = initData.map((item) => item.Relation.relationComparison);
    return uniq(connectionMlValueList).length > 1 ? undefined : get(connectionMlValueList, 0, 0);
  }
}
