import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { IReportVehicleConfigServiceKey } from '../../services/reportVehicleConfigService.interfaces';
import { ReportVehicleConfigService } from '../../services/reportVehicleConfigService';
import { IVehicleRestrictServiceKey } from '../../services/vehicleRestrictService.interfaces';
import { VehicleRestrictService } from '../../services/vehicleRestrictService';

export function bindVehicleRestrictsServices(bind: IContainerBinder) {
  bind(IReportVehicleConfigServiceKey).to(ReportVehicleConfigService);
  bind(IVehicleRestrictServiceKey).to(VehicleRestrictService);
}
