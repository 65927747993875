export module ReportVehicleParkingUtils {
  export function convertTimeWithLetters(dateString: string): string {
    if (dateString.includes('.')) {
      const [days, time] = dateString.split('.');
      const [hour, min] = time.split(':');

      const convertTime = `${hour}ч:${min}м`;

      return days === '00' ? convertTime : `${days}д / ${convertTime}`;
    }
    const [hour, min] = dateString.split(':');

    return `${hour}ч:${min}м`;
  }
}
