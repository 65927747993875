import { AxiosInstance, AxiosRequestConfig, RawAxiosRequestConfig } from 'axios';
import { injectable, inject } from 'inversify';
import { IApiEndpointService } from '~/endpoints/services/apiEndpointService.interface';
import { IInitAxiosService, IInitAxiosServiceKey } from '~/endpoints/services/initAxiosService.interface';

@injectable()
export class ApiEndpointService implements IApiEndpointService {
  private api: AxiosInstance;
  constructor(
    @inject(IInitAxiosServiceKey as symbol) private initAxiosService: IInitAxiosService,
  ) {
    this.api = initAxiosService.initAxios();
  }

  get<T = any, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<T> {
    return this.api.get(url, config);
  }

  post<T = any, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<T> {
    return this.api.post(url, data, config);
  }
}
