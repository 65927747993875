import { injectable } from 'inversify';
import { groupBy, isEqual } from 'lodash-es';
import { ReportParkedBoundedPersonPhonesDataDTO } from '~/modules/reportsCommon/reportParkedBoundedPersonPhones/common/types/reportParkedBoundedPersonPhoneData';
import { PhoneUtils } from '~/utils/phone';
import { ReportBoundedPersonPhoneDTO } from '~/types/dto/common/boundedPersonPhoneDTO';
import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';
import { IReportParkedBoundedPersonPhonesService } from './reportParkedBoundedPersonPhonesService.interface';

@injectable()
export class ReportParkedBoundedPersonPhonesService implements IReportParkedBoundedPersonPhonesService {
  getData(
    parkingSessions: Array<ReportVehicleParkingDTO>,
    boundedPersonsPhones: Array<ReportBoundedPersonPhoneDTO>,
  ): ReportParkedBoundedPersonPhonesDataDTO {
    const filteredBoundedPhone = this.filterBoundedPhones(boundedPersonsPhones);

    const boundedPhoneValues = filteredBoundedPhone.map((phone) => phone.Value);

    const filteredVehicleParkings = this.filterParkingSessions(parkingSessions, boundedPhoneValues);

    // Если номера совпадают, берем ГН указанный с этим номером
    return this.mergeVehicleParkingsPhones(filteredVehicleParkings, filteredBoundedPhone);
  }

  private filterBoundedPhones(boundedPhones: Array<ReportBoundedPersonPhoneDTO>) {
    return boundedPhones.filter((boundedPhone) => boundedPhone.Source === 10 && boundedPhone.Value != undefined);
  }

  private filterParkingSessions(parkingSessions: Array<ReportVehicleParkingDTO>, boundedPhoneValues: Array<string>) {
    return parkingSessions.filter((parking => boundedPhoneValues.includes(parking.Phone)));
  }

  // Если имя содержится в теге, то не выводим его
  private getFullName(name: string, tags: Array<string>): string {
    const matchTag = tags.find((tag) => tag === name || tag === name.split(' ').reverse().join(' '));

    if (matchTag === undefined) {
      return name;
    }

    return '';
  }

  private mergeVehicleParkingsPhones(
    parkingSessions: Array<ReportVehicleParkingDTO>,
    boundedPersonsPhones: Array<ReportBoundedPersonPhoneDTO>,
  ): ReportParkedBoundedPersonPhonesDataDTO {
    const mergedVehicleParkingsPhones = boundedPersonsPhones
      .map(({
        Tags,
        FullName,
        RelatedBirth,
        RelatedAddress,
        Value,
        Emails,
        OriginalRequestData 
      }) => {
        let parkingCarNumber = '';
  
        parkingSessions.map((parking) => {
          if (isEqual(Value, parking.Phone)) {
            parkingCarNumber = parking.CarNumber.toLowerCase();
          }
  
          return parkingCarNumber.toLowerCase();
        });

        const tags = Tags.map((tag) => tag.Tag ?? '');
  
        // Формируем обьект с нужными данными
        return {
          Phone: PhoneUtils.normalizePhoneNumber(Value),
          TagLabels: tags,
          CarNumber: parkingCarNumber,
          FullName: this.getFullName(FullName, tags),
          RelatedBirth: RelatedBirth,
          RelatedAddress: RelatedAddress,
          Emails: Emails,
          OriginalRequestData: OriginalRequestData
        };
      });
  
    // Группировка по ГН, чтобы по ГН получать массив связанных номеров с тегами
    return groupBy(mergedVehicleParkingsPhones, 'CarNumber');
  }
}
