import { injectable } from 'inversify';
import { IReportEducationConfigService } from '~/modules/reportsCommon/reportEducation/services/reportEducationsConfigServices.interfaces';
import { Config } from '~/types/field-list/config';
import { ReportEducationDTO } from '~/types/dto/common/reportEducationDTO';

@injectable()
export class ReportEducationConfigService implements IReportEducationConfigService {
  getConfig(initData: ReportEducationDTO): Config {
    const diplomaSeriesAndNumber = initData.DiplomaSeries
      ? `${initData.DiplomaSeries} ${initData.DiplomaNumber}`
      : initData.DiplomaNumber;

    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'educationType',
          label: 'Вид образования',
          value: initData.EducationType
        },
        {
          key: 'facultyName',
          label: 'Факультет',
          value: initData.FacultyName
        },
        {
          key: 'specialtyName',
          label: 'Специальность',
          value: initData.SpecialtyName,
        },
        {
          key: 'educationForm',
          label: 'Форма обучения',
          value: initData.EducationForm
        },
        {
          key: 'diplomaSeriesAndNumber',
          label: 'Серия и номер документа',
          value: diplomaSeriesAndNumber
        },
        {
          key: 'diplomaDate',
          label: 'Дата выдачи документа',
          value: initData.DiplomaDate
        },
      ]
    };
  }
}
