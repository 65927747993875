import { sortBy } from 'lodash-es'
import { LegalEntitiesDataBlobDataDTO } from "~/types/dto/blobs/legalEntitiesDataBlobDataDTO";
import { ILegalEntitiesTitleStrategy } from "./legalEntitiesTitleStrategy.interface";
import { StringUtils } from '~/utils/stringUtils';
import { DEFAULT_TITLE } from '../constants/defaultTitle';

export class LegalEntitiesTitleStrategy implements ILegalEntitiesTitleStrategy {
  constructor(private legalEntitiesDataBlobData:  LegalEntitiesDataBlobDataDTO[]) {}
  
  getReportTitle(): string {
    let companies = this.legalEntitiesDataBlobData;
  
    companies = sortBy(companies, [
      (company) => -(new Date(company.briefInfo.CompanyRegistrationDate ?? ''))
    ]);

    const companyWithName = companies.find((company) => StringUtils.isStringNotEmpty(company.commonInfo.CompanyShortName));

    if (companyWithName !== undefined) {
      return companyWithName.commonInfo.CompanyShortName;
    }

    return DEFAULT_TITLE;
  }
}