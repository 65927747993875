import { injectable } from "inversify";
import { IAnchorScrollService } from "./anchorScrollService.interfaces";

@injectable()
export class AnchorScrollService implements IAnchorScrollService {
  scrollToAnchor(id: string, isWithPosition: boolean = true, offsetElementTop: number = 115): void {
    const element = this.getTitleById(id);
    let position = this.calculatePosition(element, offsetElementTop, isWithPosition);

    window.scrollTo({ top: position, behavior: 'smooth' });
  }

  private getTitleById(id: string): HTMLElement | null {
    return document.querySelector(`#${id}`);
  }

  private calculatePosition(element: HTMLElement | null, offset: number, isWithPosition: boolean) {
    if (isWithPosition && element !== null) return element.offsetTop - offset;
    return 0;
  }
}