import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import {
  IReportConnectionParseServiceKey,
  IReportConnectionsFormatRelatedServiceKey
} from '~/modules/reportsCommon/reportConnection/services/reportConnectionsService.interfaces';
import { ReportConnectionsParseService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionsParseService';
import { ReportConnectionsFormatRelatedService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionsFormatRelatedService';
import { ReportConnectionMlValueService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionMlValueService';
import { IReportConnectionMlValueServiceKey } from '~/modules/reportsCommon/reportConnection/services/reportConnectionMlValueService.interface';
import { ReportConnectionCommunicationService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionCommunicationService';
import { IReportConnectionCommunicationServiceKey } from '~/modules/reportsCommon/reportConnection/services/reportConnectionCommunicationService.interface';
import { ReportConnectionRelationPersonService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionRelationPersonService';
import { IReportConnectionRelationPersonServiceKey } from '~/modules/reportsCommon/reportConnection/services/reportConnectionRelationPersonService.interface';

export function bindReportConnectionsServices(bind: IContainerBinder) {
  bind(IReportConnectionParseServiceKey).to(ReportConnectionsParseService);
  bind(IReportConnectionsFormatRelatedServiceKey).to(ReportConnectionsFormatRelatedService);

  bind(IReportConnectionMlValueServiceKey).to(ReportConnectionMlValueService);
  bind(IReportConnectionCommunicationServiceKey).to(ReportConnectionCommunicationService);
  bind(IReportConnectionRelationPersonServiceKey).to(ReportConnectionRelationPersonService);
}
