import { injectable } from 'inversify';
import { IReportVehicleParkingTableConfigService } from '~/modules/reportsCommon/reportVehicleParking/services/reportVehicleParkingTableConfigService.interface';
import { ReportVehicleParkingDTO } from '~/types/dto/common/vehicleParkingDTO';
import { TableItem } from '~/types/tableItem';
import { DateUtils } from '~/utils/date';
import { ReportVehicleParkingUtils } from '~/modules/reportsCommon/reportVehicleParking/utils/reportVehicleParkingUtils';
import { Header } from '~/types/table';

@injectable()
export class ReportVehicleParkingTableConfigService implements IReportVehicleParkingTableConfigService {
  getHeaders(): Array<Header> {
    return [
      {
        label: 'Телефон',
        key: 'phone',
        width: '25%',
      },
      {
        label: 'Гос.номер',
        key: 'carNumber',
      },
      {
        label: 'Парковка',
        key: 'placeCode',
      },
      {
        label: 'Дата',
        key: 'date',
      },
      {
        label: 'Время',
        key: 'time',
      },
      {
        label: 'Длительность',
        key: 'duration',
        width: '15%',
      },
    ];
  }

  getItems(initData: ReportVehicleParkingDTO[]): TableItem[] {
    return initData.map((item) => { 
      const time = DateUtils.formatTimeToHoursAndMinutes(item.StartDate);
      const duration = item.Duration ? ReportVehicleParkingUtils.convertTimeWithLetters(item.Duration) : '—';
      return {
        phone: item.Phone || '—',
        carNumber: item.CarNumber || '—',
        placeCode: `№${item.PlaceCode}` || '—',
        date: DateUtils.formatDate(item.StartDate) || '—',
        time: time || '—',
        duration: duration,
      };
    });
  }
}
