import { injectable, inject } from "inversify";
import { EMOJI_REGEXP } from "../regexp/emoji";
import { EmojiUtils } from "../utils/emoji";
import { IEmojiConverterService } from './emojiConverterService.interfaces'
import { IEmojiService, IEmojiServiceKey } from "./emojiService.interfaces";
import { CONVERTED_EMOJI_REGEXP } from "../regexp/convertedEmoji";
import { COUNTRY_EMOJI_REGEXP } from "../regexp/countryEmoji";

@injectable()
export class EmojiConverterService implements IEmojiConverterService {
  constructor(
    @inject(IEmojiServiceKey as symbol) private readonly emojiService: IEmojiService
  ) {}
  
  convertEmoji(text: string) {
    const newText = text.replaceAll('👨‍💻', '\uD83D\uDC68').replaceAll('👩‍💻', '\uD83D\uDC69');
    const emojis = newText.match(EMOJI_REGEXP);
    const DIVIDER = '<divider>'

    if (emojis === null) {
      return text;
    }

    const reducedEmojis = this.reduceFlagsEmoji(emojis);

    const filteredEmojis = reducedEmojis.filter((emoji) => isNaN(Number.parseInt(emoji)));

    return filteredEmojis.reduce((result, emoji) => {
      const emojiName = this.emojiService.getEmojiName(EmojiUtils.removeModifier(emoji));

      if (emojiName === undefined) { 
        console.error(`Emoji ${emoji} not found`);
        return result.replace(emoji, '');
      }

      return result.replace(emoji, `${DIVIDER}[[${emojiName}]]${DIVIDER}`);
    }, newText); 
  }

  checkCountryEmoji(emoji: string): boolean {
    COUNTRY_EMOJI_REGEXP.lastIndex = 0;
    return COUNTRY_EMOJI_REGEXP.test(emoji);
  }

  checkConvertedEmoji(text: string): boolean {
    CONVERTED_EMOJI_REGEXP.lastIndex = 0;
    return CONVERTED_EMOJI_REGEXP.test(text);
  }

  private reduceFlagsEmoji(emojis: string[]): string[] {
    const reducedEmojis = [];

    let index = 0;

    while (index < emojis.length) {
      const emoji = emojis[index];

      const isCountryEmoji = this.checkCountryEmoji(emoji);

      if (isCountryEmoji) {
        const nextEmoji = emojis[index + 1];
        reducedEmojis.push(emoji + nextEmoji);
        index += 2; 
      } else {
        reducedEmojis.push(emoji);
        index += 1
      }
    }

    return reducedEmojis;
  }
}