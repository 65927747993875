import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { InitAxiosService } from '~/endpoints/services/initAxiosService';
import { IInitAxiosServiceKey } from '~/endpoints/services/initAxiosService.interface';
import { AuthXApiStrategyService } from '~/endpoints/services/authXApiStrategyService';
import { IAuthBearerStrategyServiceKey, IAuthXApiStrategyServiceKey } from '~/endpoints/services/authStrategyService.interfaces';
import { AuthBearerStrategyService } from '~/endpoints/services/authBearerStrategyService';
import { ApiEndpointService } from '~/endpoints/services/apiEndpointService';
import { IApiEndpointServiceKey } from '~/endpoints/services/apiEndpointService.interface';
import { ApiStrategyService } from '~/endpoints/services/apiStrategyService';
import { IApiStrategyServiceKey } from '~/endpoints/services/apiStrategy.interface';
import { ApiEndpointAuthService } from '~/endpoints/services/apiEndpointAuthService';
import { IApiEndpointAuthServiceKey } from '~/endpoints/services/apiEndpointAuthService.interface';

export function bindApiEndpointServices(bind: IContainerBinder) {
  bind(IInitAxiosServiceKey).to(InitAxiosService);

  bind(IAuthXApiStrategyServiceKey).to(AuthXApiStrategyService);
  bind(IAuthBearerStrategyServiceKey).to(AuthBearerStrategyService);

  bind(IApiEndpointServiceKey).to(ApiEndpointService);
  bind(IApiEndpointAuthServiceKey).to(ApiEndpointAuthService);
  bind(IApiStrategyServiceKey).to(ApiStrategyService);
}
