import { injectable, inject } from 'inversify';
import { Config } from '~/types/field-list/config';
import { ReportSocialNetworkLifePositionDTO } from '~/types/dto/common/reportSocialNetworkLifePositionDTO';
import { FIELD_LIST_SOCIAL_OPTIONS } from '../../configuration/constants';
import { IReportSocialNetworkLifePositionConfigService } from './reportSocialNetworkConfigService.interfaces';
import {
  IValueVerificationService,
  IValueVerificationServiceKey
} from '~/modules/reportsCommon/common/serices/valueVerificationService.interface';

@injectable()
export class ReportSocialNetworkLifePositionConfigService implements IReportSocialNetworkLifePositionConfigService {
  constructor(
    @inject(IValueVerificationServiceKey as symbol) private valueVerificationService: IValueVerificationService
  ) {}

  getConfig(socialItem: ReportSocialNetworkLifePositionDTO): Config {
    return {
      ...FIELD_LIST_SOCIAL_OPTIONS,
      fields: [
        {
          key: 'PoliticalPreferences',
          label: 'Политические предпочтения',
          value: this.valueVerificationService.valueVerification(socialItem.PoliticalPreferences, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'WorldView',
          label: 'Мировоззрение',
          value: this.valueVerificationService.valueVerification(socialItem.WorldView, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'MainThingInLife',
          label: 'Главное в жизни',
          value: this.valueVerificationService.valueVerification(socialItem.MainThingInLife, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'MainThingInPeople',
          label: 'Главное в людях',
          value: this.valueVerificationService.valueVerification(socialItem.MainThingInPeople, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'SmokingView',
          label: 'Отношение к курению',
          value: this.valueVerificationService.valueVerification(socialItem.SmokingView, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'AlcoholView',
          label: 'Отношение к алкоголю',
          value: this.valueVerificationService.valueVerification(socialItem.AlcoholView, (item) => this.positionValueVerification(item)),
        },
        {
          key: 'Inspires',
          label: 'Вдохновляют',
          value: this.valueVerificationService.valueVerification(socialItem.Inspires, (item) => this.positionValueVerification(item)),
        },
      ].filter(item => item.value)
    };
  }

  private positionValueVerification<T>(item: T): boolean {
    return item != 'Не указано';
  }
}
