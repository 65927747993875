import { ReportVehicleDTO } from '~/types/dto/common/reportVehicleDTO';
import { ReportVehicleRestricts } from '../types/reportVehicleRestricts';
import { IVehicleRestrictService } from './vehicleRestrictService.interfaces';
import { injectable } from 'inversify';

@injectable()
export class VehicleRestrictService implements IVehicleRestrictService {
  convertReportVehicleToReportVehicleRestricts(vehicle: ReportVehicleDTO): Array<ReportVehicleRestricts> {
    return vehicle.Restricts.map((restrict) => {
      const brand = vehicle.Brand !== vehicle.Model ? `${vehicle.Brand} ${vehicle.Model}` : vehicle.Brand;

      return {
        Reason: restrict.Reason,
        Brand: brand,
        Region: restrict.Region,
        Type: restrict.Type
      }; 
    });
  }
}
