import { ReportAddressDTO } from "~/types/dto/common/addressDTO";
import { MergeService } from "./merge/merge-service";
import { injectable } from "inversify";

@injectable()
export class ReportAddressMergeService extends MergeService<ReportAddressDTO> {
  protected canMerge(leftItem: ReportAddressDTO, rightItem: ReportAddressDTO): boolean {
    return leftItem.Address.includes(rightItem.Address) || rightItem.Address.includes(leftItem.Address);
  }

  protected mergeFields(leftItem: ReportAddressDTO, rightItem: ReportAddressDTO): ReportAddressDTO {
    return {
      Address: leftItem.Address.length > rightItem.Address.length ? leftItem.Address : rightItem.Address,
      AddressType: leftItem.AddressType ?? rightItem.AddressType,
      AvailabilityHouseInFias: leftItem.AvailabilityHouseInFias ?? rightItem.AvailabilityHouseInFias,
      CapitalMarker: leftItem.CapitalMarker ?? rightItem.CapitalMarker,
      CodeSuitabilityMailing: leftItem.CodeSuitabilityMailing ?? rightItem.CodeSuitabilityMailing,
      CoordinateAccuracyCode: leftItem.CoordinateAccuracyCode ?? rightItem.CoordinateAccuracyCode,
      FiasActualityState: leftItem.FiasActualityState ?? rightItem.FiasActualityState,
      FiasId: leftItem.FiasId ?? rightItem.FiasId,
      FiasLevel: leftItem.FiasLevel ?? rightItem.FiasLevel,
      FlatArea: leftItem.FlatArea ?? rightItem.FlatArea,
      FlatPrice: leftItem.FlatPrice ?? rightItem.FlatPrice,
      FlatType: leftItem.FlatType ?? rightItem.FlatType,
      GeoLat: leftItem.GeoLat ?? rightItem.GeoLat,
      GeoLon: leftItem.GeoLon ?? rightItem.GeoLon,
      KladrId: leftItem.KladrId ?? rightItem.KladrId,
      NameStationMetros: leftItem.NameStationMetros ?? rightItem.NameStationMetros,
      PostalBox: leftItem.PostalBox ?? rightItem.PostalBox,
      SquareMeterPrice: leftItem.SquareMeterPrice ?? rightItem.SquareMeterPrice,
      TaxOffice: leftItem.TaxOffice ?? rightItem.TaxOffice,
      TaxOfficeLegal: leftItem.TaxOfficeLegal ?? rightItem.TaxOfficeLegal,
      TimeZone: leftItem.TimeZone ?? rightItem.TimeZone,
      UnparsedParts: leftItem.UnparsedParts ?? rightItem.UnparsedParts,
      VerificationCode: leftItem.VerificationCode ?? rightItem.VerificationCode,
    }
  }
}