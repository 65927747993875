import useEnvironment from '../../../composables/useEnvironment';
import { Environment } from '@/types/Environment';
import { UIReportType } from '~/types/reportType';
import { IPageTitleStrategy } from '~/modules/app/services/pageTitleStrategy.interface';
import { LegalEntitiesTitleStrategy } from '@/modules/app/services/legalEntitiesTitleStrategy';
import useLegalEntitiesStoreStore from '~/stores/legalEntities/legalEntitiesStore';
import { VehicleTitleStrategy } from '~/modules/app/services/vehicleTitleStrategy';
import useVehicleVehiclesStore from '@/stores/vehicle/vehiclesStore';
import { PhoneNumberTitleStrategy } from '~/modules/app/services/phoneNumberTitleStrategy';
import usePhoneNumberStore from '~/stores/phone-number/phoneNumberStore';
import { IndividualTitleStrategy } from '~/modules/app/services/individualTitleStrategy';
import useIndividualStore from '@/stores/individual/individualStore';
import { useDataBlobMetadataStore } from '@/stores/dataBlobMetadataStore';
import { DefaultTitleStrategy } from '~/modules/app/services/defaultTitleStrategy';
import { usePageType } from './usePageType';
import { PageType } from '../types/pageType';
import { NotFoundTitleStrategy } from '../services/notFoundTitleStrategy';

export function usePageTitle(): ComputedRef<string> {
  const environment = useEnvironment();

  return computed(() => {
    if (environment === Environment.client) {
      const pageType = usePageType();

      const strategy = getStrategyByReportType(pageType.value);

      return strategy.getReportTitle();
    }

    return getStrategyByReportType(PageType.unknown).getReportTitle();
  })
}

function getStrategyByReportType(pageType: PageType): IPageTitleStrategy {
  const isLegalEntitiesPage = pageType === PageType.legalEntitiesPage;
  const isVehiclePage = pageType === PageType.vehiclePage;
  const isPhoneNumberPage = pageType === PageType.phoneNumberPage;
  const isIndividualPage = pageType === PageType.individualPage;
  const isNotFoundPage = pageType === PageType.notFoundPage;

  if (isLegalEntitiesPage) {
    const companiesStore = useLegalEntitiesStoreStore();
    
    return new LegalEntitiesTitleStrategy([...(companiesStore.value.companies ?? [])]);
  }

  if (isVehiclePage) {
    const vehicleVehiclesStore = useVehicleVehiclesStore();

    return new VehicleTitleStrategy(vehicleVehiclesStore.value);
  }

  if (isPhoneNumberPage) {
    const phoneNumberStore = usePhoneNumberStore();

    return new PhoneNumberTitleStrategy(phoneNumberStore.value.phoneNumberData);
  }

  if (isIndividualPage) {
    const individualStore = useIndividualStore();
    const dataBlobMetadataStore = useDataBlobMetadataStore(UIReportType.fl);

    return new IndividualTitleStrategy(individualStore.value, dataBlobMetadataStore.metaData);
  }

  if (isNotFoundPage) {
    return new NotFoundTitleStrategy();
  }

  return new DefaultTitleStrategy();
}