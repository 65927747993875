import { PhoneNumbersDataBlobDataDTO } from "~/types/dto/blobs/phoneNumbersDataBlobDataDTO";
import { IPhoneNumberTitleStrategy } from "./phoneNumberTitleStrategy.interface";
import { PhoneUtils } from "~/utils/phone";

export class PhoneNumberTitleStrategy implements IPhoneNumberTitleStrategy {
  constructor(private phoneNumberData: PhoneNumbersDataBlobDataDTO) {}
  
  getReportTitle(): string {
    return PhoneUtils.formatPhoneNumber(this.phoneNumberData.Common.PhoneNumber);
  }
}