import { ReportVehicleDTO } from '~/types/dto/common/reportVehicleDTO';
import { ReportVehicleRestricts } from '../types/reportVehicleRestricts';
import { InjectionKey } from 'vue';


export interface IVehicleRestrictService {
  convertReportVehicleToReportVehicleRestricts(vehicle: ReportVehicleDTO): Array<ReportVehicleRestricts>;
}

export const IVehicleRestrictServiceKey: InjectionKey<IVehicleRestrictService> = Symbol('IVehicleRestrictService');
