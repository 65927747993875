import { IndividualDataBlobDataDTO } from "~/types/dto/blobs/individualDataBlobDataDTO";
import { IIndividualTitleStrategy } from "./individualTitleStrategy.interface";
import { DataBlobMetadataDTO } from "~/types/dto/common/dataBlobMetadata";
import { StringUtils } from "~/utils/stringUtils";

export class IndividualTitleStrategy implements IIndividualTitleStrategy {
  private readonly PHOTO_REPORT_TITLE = 'Отчет по фотографии';
  
  constructor(private individualDataBlobDataDTO: IndividualDataBlobDataDTO, private dataBlobMetadataDTO: DataBlobMetadataDTO) {}

  getReportTitle(): string {
    if (this.checkSearchOnPhoto()) {
      return this.PHOTO_REPORT_TITLE;
    }

    return this.individualDataBlobDataDTO.Common.FullName;
  }

  private checkSearchOnPhoto(): boolean {
    const originalInput = this.dataBlobMetadataDTO.OriginalInput;
    
    const checkData = [originalInput.PhotoId, originalInput.PhotoInfo.FileName, originalInput.PhotoInfo.FileNameWithoutExtension]

    return checkData.every((data) => StringUtils.isStringNotEmpty(data)) && originalInput.PhotoInfo.DataLength > 0;
  }
}