import { injectable, inject } from 'inversify';
import { IReportPersonSnilsConfigServices } from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsConfigServices.interfaces';
import { ReportPersonSnilsDTO } from '~/types/dto/common/reportPersonSnilsDTO';
import { Config } from '~/types/field-list/config';
import { StringUtils } from '~/utils/stringUtils';
import {
  IReportPersonSnilsService,
  IReportPersonSnilsServiceKey
} from '~/modules/reportsCommon/reportPersonSnils/services/reportPersonSnilsService.interface';

@injectable()
export class ReportPersonSnilsConfigServices implements IReportPersonSnilsConfigServices {
  constructor(
    @inject(IReportPersonSnilsServiceKey as symbol) private reportPersonSnilsService: IReportPersonSnilsService
  ) {
  }
  getConfig(initData: ReportPersonSnilsDTO): Config {
    const isVerify = this.reportPersonSnilsService.checkVerifySnils(initData.CorrespondingPerson);

    const descriptionValue = isVerify ? initData.CorrespondingPerson.replace(', не верифицирован', '') : initData.CorrespondingPerson;

    const value = StringUtils.isStringNotEmpty(initData.CorrespondingPerson)
      ? { value: isVerify, description: this.reportPersonSnilsService.formattingDateInString(descriptionValue) }
      : 'Верификация не проводилась';

    return {
      fieldsWidth: '100%',
      fieldsDirection: 'divider-row',
      fieldsValueWidth: '350px',
      isBeginningDivider: true,
      fields: [
        {
          key: 'snils',
          label: initData.Value,
          value
        }]
    };
  }
}
