import { ReportPhotosUrlDTO } from "~/types/dto/common/ReportPhotosUrlDTO";
import { loadImage } from '@/modules/gallery/common/utils/imageUtils';
import { ILoadingImageService } from "./loadingImageService.interfaces";
import { injectable } from 'inversify'

@injectable()
export class LoadingImageService implements ILoadingImageService {
  async *getLoadedImages(images: Array<ReportPhotosUrlDTO>) {
    for (let image of images) {
      try {
        await loadImage(image.Value);

        yield image;
      } catch (error) {
        console.log('Image not loaded: ', error);
      }
    }
  }
}