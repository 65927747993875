import { InjectionKey } from "nuxt/dist/app/compat/capi";
import { FieldDescription } from "../types/field-description";
import { FieldMargin } from "../types/field-margin";
import { FieldValue, FieldValueObject } from "../types/field-value";

export interface IFieldListGuard {
  isFieldValueObject(value: FieldValue): value is FieldValueObject;
  isFieldMargin(value: FieldMargin | string | undefined): value is FieldMargin;
  isFieldDescription(value: unknown): value is FieldDescription;
}

export const IFieldListGuardKey: InjectionKey<IFieldListGuard> = Symbol('IFieldListGuard');
