import { InjectionKey } from 'vue';


export interface IEmojiConverterService {
  convertEmoji(text: string): string;
  checkConvertedEmoji(text: string): boolean;
  checkCountryEmoji(emoji: string): boolean;
}

export const IEmojiConverterServiceKey: InjectionKey<IEmojiConverterService> = Symbol('IEmojiConverterService');
