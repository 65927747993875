import { uniqWith } from 'lodash-es';
import { StringUtils } from '~/utils/stringUtils';
import { ListUtils } from '~/utils/listUtils';

import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';
import { ReportConnectionRelatedChainDTO } from '~/types/dto/common/connectionRelatedChainDTO';

function entitiesComparator(l: string, r: string) {
  return (l === '' && r === '') || StringUtils.isEqualStrings(l, r);
}

function relationsComparator(l: ReportConnectionRelatedRelationDTO, r: ReportConnectionRelatedRelationDTO) {
  const relationMatch = StringUtils.isEqualStrings(l.DisplayName, r.DisplayName) && l.Type === r.Type;

  return l.DisplayName === '' && r.DisplayName === '' || relationMatch;
}

export module ConnectionsUtils {
  export function getUniqConnections(connections: ReportConnectionRelatedChainDTO[]): ReportConnectionRelatedChainDTO[] {
    return uniqWith(connections, (arrVal, othVal) => {
      const conEntNames = arrVal.Entities.map((entity) => entity.Name);
      const othConEntNames = othVal.Entities.map((entity) => entity.Name);

      const entitiesIsEqual = ListUtils.compareLists(conEntNames, othConEntNames, entitiesComparator);
      const relationsIsEqual = ListUtils.compareLists(arrVal.Relations, othVal.Relations, relationsComparator);

      return entitiesIsEqual && relationsIsEqual;
    });
  }
}
