import { Field } from '../types/field';
import { IFieldListFilter } from './fieldListFilter.interfaces';
import { IFieldListGuard, IFieldListGuardKey } from './fieldListGuard.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export class FieldListFilter implements IFieldListFilter {
  constructor(
    @inject(IFieldListGuardKey as symbol) private readonly fieldListGuard: IFieldListGuard,
  ) {}

  filterFields(fields: Field[]): Array<Field> {
    return fields.filter(this.filterField.bind(this));
  }

  private filterField(field: Field): boolean {
    if (this.fieldListGuard.isFieldValueObject(field.value)) {
      return field.value.text !== null && field.value.text !== undefined && field.value.text !== '';
    }
  
    return field.value !== null && field.value !== undefined && field.value !== '';
  }
}