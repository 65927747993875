import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindReportVehicleParkingServices } from '~/modules/reportsCommon/reportVehicleParking/configuration/inversify/container.reportVehicleParking';
import { bindReportPersonSnilsServices } from '~/modules/reportsCommon/reportPersonSnils/configuration/inversify/container.reportPersonSnilsServices';
import { bindReportMovementsServices } from '~/modules/reportsCommon/reportMovement/common/configuration/inversify/container.reportMovementsServices';
import { bindReportConnectionsServices } from '~/modules/reportsCommon/reportConnection/configuration/inversify/container.reportConnectionsServices';
import { bindSubscriberNumberService } from '~/modules/reportsCommon/reportSubscriberNumber/configuration/inversify/container.reportSubscriberNumber';
import { bindReportEducationsServices } from '~/modules/reportsCommon/reportEducation/confiration/inversify/container.reportEducations';
import { bindReportJobServices } from '~/modules/reportsCommon/reportJobs/configuration/inversify/container.reportJob';
import { bindLegalEntitiesParticipationServices } from '~/modules/reportsCommon/reportLegalEntitiesParticipation/configuration/inversify/container.legalEntitiesParticipationServices';
import { bindVehicleRestrictsServices } from '~/modules/reportsCommon/reportVehicleRestricts/configuration/inversify/container.reportVehicleRestricts';
import { bindReportBoundedPhonesService } from '~/modules/reportsCommon/reportBoundedPhones/configuration/inversify/container.reportBoundedPhones';
import { bindFineServices } from '~/modules/reportsCommon/reportFine/configuration/inversify/container.fineServices';
import { bindPossibleDataServices } from '~/modules/reportsCommon/reportPossible/individual/configuration/inversify/container.possibleData';
import { bindMessageBoardsService } from '~/modules/reportsCommon/reportMessageBoardRecord/configuration/inversify/container.messageBoards';
import { bindSocialNetworksServices } from '~/modules/reportsCommon/reportSocialNetworks/configuration/inversify/container.socialNetworks';
import { bindPassportsService } from '~/modules/reportsCommon/reportPassport/configuration/inversify/container.passportsCard';
import { bindReportParkedPhoneNumbersService } from '~/modules/reportsCommon/reportParkedBoundedPersonPhones/configuration/inversify/container.reportBoundedPhones';
import { bindReportAddressService } from '~/modules/reportsCommon/reportAddress/configuration/inversify/container.reportAddressService';
import { bindReportGalleryServices } from '~/modules/gallery/common/configuration/inversify/container.reportGallery';
import { bindFieldListServices } from '~/modules/fieldList/configurations/inversify/container.fieldList';
import { bindNavBar } from '~/modules/navigation/navBar/configuration/inversify/container.navBar';
import { ValueVerificationService } from '~/modules/reportsCommon/common/serices/valueVerificationService';
import { IValueVerificationServiceKey } from '~/modules/reportsCommon/common/serices/valueVerificationService.interface';

export function bindReportsCommonModulesServices(bind: IContainerBinder) {
  bindReportVehicleParkingServices(bind);
  bindReportPersonSnilsServices(bind);
  bindReportMovementsServices(bind);
  bindReportConnectionsServices(bind);
  bindSubscriberNumberService(bind);
  bindReportEducationsServices(bind);
  bindReportJobServices(bind);
  bindLegalEntitiesParticipationServices(bind);
  bindVehicleRestrictsServices(bind);
  bindReportBoundedPhonesService(bind);
  bindNavBar(bind);
  bindFineServices(bind);
  bindPossibleDataServices(bind);
  bindMessageBoardsService(bind);
  bindSocialNetworksServices(bind);
  bindPassportsService(bind);
  bindReportParkedPhoneNumbersService(bind);
  bindReportAddressService(bind);
  bindReportGalleryServices(bind);
  bindFieldListServices(bind);

  bind(IValueVerificationServiceKey).to(ValueVerificationService);
}
