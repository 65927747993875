import { VehiclesDataBlobDataDTO } from "~/types/dto/blobs/vehiclesDataBlobDataDTO";
import { get } from 'lodash-es';
import { IVehicleTitleStrategy } from './vehicleTitleStrategy.interface'
import { DEFAULT_TITLE } from '../constants/defaultTitle';

export class VehicleTitleStrategy implements IVehicleTitleStrategy {
  constructor(private vehicleDataBlob: VehiclesDataBlobDataDTO) {}
  
  getReportTitle(): string {
    
    const ownedVehicle = get(this.vehicleDataBlob.Vehicles, 0, undefined);
    
    if (ownedVehicle !== undefined) {
      return `${ownedVehicle.CarNumber} ${ownedVehicle.CarNumberRegion}`;
    }

    return DEFAULT_TITLE;
  }
}