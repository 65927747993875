import { IContainerBinder } from '~/configuration/inversify/containerAccessor.interface';
import { bindLegalEntitiesModulesServices } from '~/modules/legalEntities/common/configurations/inversify/container.legalEntitiesModulesServices';
import { bindIndividualModulesServices } from '~/modules/individual/common/configurations/inversify/container.individualModulesServices';
import { bindReportsCommonModulesServices } from '~/modules/reportsCommon/common/configurations/inversify/container.reportsCommonModulesServices';
import { bindTable } from '~/modules/table/configuration/inversify/container.table';
import { bindEmojiServices } from '~/modules/emoji/configurations/inversify/container.emoji';
import { bindAuthServices } from '~/modules/auth/configuration/inversify/container.authServices';

export function bindModuleServices(bind: IContainerBinder) {
  bindLegalEntitiesModulesServices(bind);
  bindIndividualModulesServices(bind);
  bindReportsCommonModulesServices(bind);
  bindTable(bind);
  bindEmojiServices(bind);
  bindAuthServices(bind);
}
