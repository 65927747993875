import { IContainerBinder } from "~/configuration/inversify/containerAccessor.interface";
import { IFieldListGuardKey } from "../../services/fieldListGuard.interfaces";
import { FieldListGuard } from "../../services/fieldListGuard";
import { IFieldListFilterKey } from "../../services/fieldListFilter.interfaces";
import { FieldListFilter } from "../../services/fieldListFilter";
import { FieldListMapper } from '../../services/fieldListMapper';
import { IFieldListMapperKey } from '../../services/fieldListMapper.interfaces';

export function bindFieldListServices(bind: IContainerBinder) {
  bind(IFieldListGuardKey).to(FieldListGuard);
  bind(IFieldListFilterKey).to(FieldListFilter);
  bind(IFieldListMapperKey).to(FieldListMapper);
}