import { injectable, inject } from 'inversify';
import { APIReportType } from '~/types/reportType';
import { IReportsService } from './reports-service.interfaces';
import { ReportGenerationTask } from '~/types/report/report';
import { useTasksEvents } from '@/composables/useTasksEvents';
import { IApiEndpointService } from '~/endpoints/services/apiEndpointService.interface';
import { IApiEndpointAuthServiceKey } from '~/endpoints/services/apiEndpointAuthService.interface';

@injectable()
export class ReportsService implements IReportsService {
  private readonly BASE_URL_V2 = '/api/v2/async-pdf/tasks';
  private readonly BASE_URL_V3 = '/api/v3/async-pdf';

  constructor(
    @inject(IApiEndpointAuthServiceKey as symbol) private api: IApiEndpointService
  ) {}

  async addReportsToList(report: File[]): Promise<void> {
    const formData = new FormData();

    const tasksEvents = useTasksEvents();

    report.forEach((item) => formData.append('filesToHandle', item));

    const response = await this.api.post(
      `${this.BASE_URL_V3}/tasks/generate-from-multiple-files`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    tasksEvents.tasksCreated.emit(response.data);
  }

  async generateReport(reportType: APIReportType, report: File): Promise<void> {
    const tasksEvents = useTasksEvents();

    const formData = new FormData();
    formData.append('jsonFile', report);

    const response = await this.api.post(
      `${this.BASE_URL_V2}/generate-from-file`,
      formData,
      {
        params: {
          reportType,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    tasksEvents.taskCreated.emit(response.data);
  }

  async generateReportManually(reportTakId: string): Promise<ReportGenerationTask> {
    const response = await this.api.post(`${this.BASE_URL_V3}/generation/${reportTakId}`);

    return response.data;
  }
}
