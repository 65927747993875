import { defineStore } from 'pinia';
import useEnvironment from '~/composables/useEnvironment';

import { useInjectable } from '~/hooks/useInjectable';
import { Environment } from '~/types/Environment';
import { UIReportType } from '~/types/reportType';
import { useDataStore } from './dataStore';
import { IMetaDataAdapterServiceKey } from '~/services/adapters/metaDataAdapterService.interface';

export function useDataBlobMetadataStore(
  reportType: UIReportType
) {
  const storeFactory = defineStore(`dataBlobMetadataStore: ${reportType}`, () => {
    const metaDataAdapterService = useInjectable(IMetaDataAdapterServiceKey);
    const dataStore = useDataStore(reportType);

    const metaData = computed(() => metaDataAdapterService.getData(dataStore.data));
    
    const { initialize } = dataStore;

    return { metaData, initialize };
  });
  
  const environment = useEnvironment();
  const store = storeFactory();

  if (environment == Environment.server) {
    useLazyAsyncData(() => store.initialize());
  }

  return store;
}
