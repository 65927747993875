import { InjectionKey } from "nuxt/dist/app/compat/capi";
import { ReportNavData } from "../types/reportNavData";
import { ReportNavLinksType } from "../types/reportNavLinksType";

export interface INavBarService {
  getNavLinkItem(id: ReportNavLinksType): ReportNavData;
  findActiveNavItem(offsetHeight: number): string;
}

export const INavBarServiceKey: InjectionKey<INavBarService> = Symbol('INavBarService');
