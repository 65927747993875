import { injectable, inject } from 'inversify';
import { IApiStrategyService } from '~/endpoints/services/apiStrategy.interface';
import useEnvironment from '~/composables/useEnvironment';
import { Environment } from '~/types/Environment';
import { AuthStrategy } from '~/endpoints/types/AuthStrategy';
import {
  IAuthBearerStrategyService,
  IAuthBearerStrategyServiceKey,
  IAuthXApiStrategyService,
  IAuthXApiStrategyServiceKey
} from '~/endpoints/services/authStrategyService.interfaces';

@injectable()
export class ApiStrategyService implements IApiStrategyService {
  constructor(
    @inject(IAuthXApiStrategyServiceKey as symbol) private xApiStrategyService: IAuthXApiStrategyService,
    @inject(IAuthBearerStrategyServiceKey as symbol) private bearerStrategyService: IAuthBearerStrategyService,
  ) {
  }
  getActualStrategy(): AuthStrategy {
    const environment = useEnvironment();

    if (environment == Environment.server) {
      return this.xApiStrategyService.getAuthHeadersProperty();
    }

    return this.bearerStrategyService.getAuthHeadersProperty();
  }
}
