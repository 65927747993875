import { InjectionKey } from 'vue';
import { ReportConnectionRelatedDTO } from '~/types/dto/common/reportConnectionRelatedDTO';
import { ReportConnectionRelatedEntityDTO } from '~/types/dto/common/reportConnectionRelatedEntityDTO';
import { ReportConnectionRelatedRelationDTO } from '~/types/dto/common/reportConnectionRelatedRelationDTO';
import { ConnectionItemInfo } from '~/modules/reportsCommon/reportConnection/types/connectionItemInfo';
import { ReportConnectionRelatedChainDTO } from '~/types/dto/common/connectionRelatedChainDTO';

export type ReportConnectionRelatedInfo = {
  RelatedEntities: Array<ReportConnectionRelatedEntityDTO>,
  RelatedRelation: ReportConnectionRelatedRelationDTO,
}

export type ReportConnectionParsedChains = Array<ReportConnectionRelatedInfo[]>

export interface IReportConnectionParseService {
  getParsedConnections(connections: ReportConnectionRelatedChainDTO[]): ReportConnectionParsedChains;
}

export interface IReportConnectionsFormatRelatedService {
  getFormattedRelated(relatedInfo: Array<ReportConnectionRelatedInfo>): Array<ConnectionItemInfo>;
}

export const IReportConnectionParseServiceKey: InjectionKey<IReportConnectionParseService> = Symbol('IReportConnectionParseService');
export const IReportConnectionsFormatRelatedServiceKey: InjectionKey<IReportConnectionsFormatRelatedService> = Symbol('IReportConnectionsFormatRelatedService');
