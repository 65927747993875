import { uniq } from 'lodash-es';
import { MergeService } from '~/services/merge/merge-service';
import { ReportParkedBoundedPhoneDTO } from '~/modules/reportsCommon/reportParkedBoundedPersonPhones/common/types/reportParkedBoundedPhone';
import { StringUtils } from '~/utils/stringUtils';

export class ReportParkedBoundedPersonPhonesMergeService extends MergeService<ReportParkedBoundedPhoneDTO> {
  protected canMerge(leftItem: ReportParkedBoundedPhoneDTO, rightItem: ReportParkedBoundedPhoneDTO): boolean {
    return leftItem.Phone === rightItem.Phone;
  }

  protected mergeFields(leftItem: ReportParkedBoundedPhoneDTO, rightItem: ReportParkedBoundedPhoneDTO): ReportParkedBoundedPhoneDTO {
    const mergedTags = uniq([...leftItem.TagLabels, ...rightItem.TagLabels]);

    const fullName = [leftItem.FullName, rightItem.FullName].filter((name) => StringUtils.isStringNotEmpty(name)).join(', ');

    return {
      Phone: leftItem.Phone || rightItem.Phone,
      TagLabels: mergedTags,
      CarNumber: leftItem.CarNumber || rightItem.CarNumber,
      FullName: fullName,
      RelatedBirth: leftItem.RelatedBirth || rightItem.RelatedBirth,
      RelatedAddress: leftItem.RelatedAddress || rightItem.RelatedAddress,
      Emails: leftItem.Emails || rightItem.Emails,
      OriginalRequestData: leftItem.OriginalRequestData ?? rightItem.OriginalRequestData
    };
  }
}
