import { injectable, inject } from 'inversify';
import { AxiosHeaders, AxiosInstance, AxiosRequestConfig, RawAxiosRequestConfig } from 'axios';
import { IApiEndpointAuthService } from '~/endpoints/services/apiEndpointAuthService.interface';
import { IApiStrategyService, IApiStrategyServiceKey } from '~/endpoints/services/apiStrategy.interface';
import { IInitAxiosService, IInitAxiosServiceKey } from '~/endpoints/services/initAxiosService.interface';
import { Constants } from '~/utils/constants';

@injectable()
export class ApiEndpointAuthService implements IApiEndpointAuthService {
  private api: AxiosInstance;
  constructor(
    @inject(IInitAxiosServiceKey as symbol) private initAxiosService: IInitAxiosService,
    @inject(IApiStrategyServiceKey as symbol) private strategy: IApiStrategyService,
  ) {
    this.api = initAxiosService.initAxios();
  }

  private handleError(error: any) {
    if (error.response.status === Constants.HTTP_STATUS_CODES.HTTP_STATUS_CODE_401) {
      navigateTo('/auth');
    }
  }

  async get<T = any, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<T> {
    try {
      const strategy = this.strategy.getActualStrategy();
      return await this.api.get(
        url,
        {
          ...config,
          headers: {
            ...strategy,
            ...config?.headers
          } as AxiosHeaders
        }
      );
    } catch (error) {
      this.handleError(error);
      return Promise.reject(error);
    }
  }

  async post<T = any, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<T> {
    try {
      const strategy = this.strategy.getActualStrategy();
      return await this.api.post(
        url,
        data,
        {
          ...config,
          headers: {
            ...strategy,
            ...config?.headers
          } as AxiosHeaders
        }
      );
    } catch (error) {
      this.handleError(error);
      return Promise.reject(error);
    }
  }
}
