import { injectable } from 'inversify';
import { IReportConnectionCommunicationService } from '~/modules/reportsCommon/reportConnection/services/reportConnectionCommunicationService.interface';

@injectable()
export class ReportConnectionCommunicationService implements IReportConnectionCommunicationService {
  getCommunicationLevel(level: number): string {
    switch (level) {
      case 1:
        return 'Близкая связь';
      case 2:
        return 'Средняя связь';
      case 3:
      default:
        return 'Дальняя связь';
    }
  }

  calculateCommunicationLevel(value: number): number {
    return Math.round(value / 2);
  }
}
