import { injectable } from "inversify";
import { NavBarConstant } from "../configuration/constants";
import { ReportNavData } from "../types/reportNavData";
import { ReportNavLinksType } from "../types/reportNavLinksType";
import { INavBarService } from "./navBarService.interfaces";
import { StringUtils } from "~/utils/stringUtils";

@injectable()
export class NavBarService implements INavBarService {
  private readonly MIDDLE_TITLE_COMPONENT_CLASS = '.middle-title';

  
  getNavLinkItem(id: ReportNavLinksType): ReportNavData {
    return NavBarConstant.NAV_LINK_COLLECTION[id];
  }

  findActiveNavItem(offsetHeight: number): string {
    const scrollDistance = window.scrollY;
    const isBottom = document.documentElement.scrollHeight - scrollDistance - document.documentElement.clientHeight <= 20;

    const anchorElements = this.getAnchorElements();

    if (isBottom) {
      const element = anchorElements.at(-1);

      if (element !== undefined) {
        return element.innerText;
      }
    }

    const reversedAnchorElements = anchorElements.slice().reverse();

    const foundElement = reversedAnchorElements.find((element) => {
      if (element.offsetTop - offsetHeight <= scrollDistance) {
        return element;
      }
    });

    if (foundElement !== undefined) {
      return foundElement.innerText;
    }
    
    return anchorElements[0].innerText;
  }

  private getAnchorElements(): HTMLElement[] {
    const elements = [...document.querySelectorAll(this.MIDDLE_TITLE_COMPONENT_CLASS)];
    return elements.filter(
      (element) => this.checkValidElement(element) ? element.offsetHeight > 0 : false
    ) as HTMLElement[];
  }

  private checkValidElement(element: Element): element is HTMLElement {
    return element instanceof HTMLElement && StringUtils.isStringNotEmpty(element.id);
  }
}