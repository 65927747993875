import { ReportNavLinksType } from '~/modules/navigation/navBar/types/reportNavLinksType';

export module NavBarConstant {
  export const NAV_LINK_COLLECTION = {
    [ReportNavLinksType.Card]: { id: 'Card', order: 0, name: 'Карточка' },
    [ReportNavLinksType.RegistrationData]: { id: 'RegistrationData', order: 1, name: 'Регистрационные данные' },
    [ReportNavLinksType.CompanyStruct]: { id: 'CompanyStruct', order: 2, name: 'Структура компании' },
    [ReportNavLinksType.Employees]: { id: 'Employees', order: 3, name: 'Сотрудники' },
    [ReportNavLinksType.ChangeHistory]: { id: 'ChangeHistory', order: 4, name: 'История изменений' },
    [ReportNavLinksType.CompanyActivities]: { id: 'CompanyActivities', order: 5, name: 'Деятельность компании' },
    [ReportNavLinksType.Property]: { id: 'Property', order: 6, name: 'Имущество' },
    [ReportNavLinksType.FinancialAnalytics]: { id: 'FinancialAnalytics', order: 7, name: 'Финансовая аналитика' },

    [ReportNavLinksType.GeneralInformation]: { id: 'GeneralInformation', order: 110, name: 'Общая информация' },
    [ReportNavLinksType.ContactsInfo]: { id: 'ContactsInfo', order: 111, name: 'Контактные данные' },
    [ReportNavLinksType.Family]: { id: 'Family', order: 112, name: 'Cемья' },
    [ReportNavLinksType.LifePath]: { id: 'LifePath', order: 113, name: 'Жизненный путь' },
    [ReportNavLinksType.ScoringScores]: { id: 'ScoringScores', order: 114, name: 'Скоринговые оценки' },
    [ReportNavLinksType.NegativeInfo]: { id: 'NegativeInfo', order: 115, name: 'Негативная информация' },
    [ReportNavLinksType.PropertyInfo]: { id: 'PropertyInfo', order: 116, name: 'Недвижимое имущество' },
    [ReportNavLinksType.MovableProperty]: { id: 'MovableProperty', order: 117, name: 'Движимое имущество' },
    [ReportNavLinksType.Advertisement]: { id: 'Advertisement', order: 118, name: 'Объявления' },
    [ReportNavLinksType.Movements]: { id: 'Movements', order: 119, name: 'Перемещения' },
    [ReportNavLinksType.BoundedPersonPhones]: { id: 'BoundedPersonPhones', order: 120, name: 'Связанные абонентские номера' },
    [ReportNavLinksType.SocialNetworks]: { id: 'SocialNetworks', order: 151, name: 'Аккаунты в социальных сетях' },

    [ReportNavLinksType.Connections]: { id: 'Connections', order: 150, name: 'Связи' },

    [ReportNavLinksType.Unknown]: { id: '', order: 0, name: '' }
  };
}
