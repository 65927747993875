import { injectable } from 'inversify';
import { IValueVerificationService } from '~/modules/reportsCommon/common/serices/valueVerificationService.interface';

@injectable()
export class ValueVerificationService implements IValueVerificationService {
  valueVerification<T>(value: T, verification: (item: T) => boolean): T | undefined {
    const isVerified = verification(value);
    return isVerified ? value : undefined;
  }
}
