import { InjectionKey } from 'vue';
import { AuthStrategy } from '~/endpoints/types/AuthStrategy';

export interface IAuthStrategy {
  getAuthHeadersProperty(): AuthStrategy;
}

export type IAuthXApiStrategyService = IAuthStrategy;
export type IAuthBearerStrategyService = IAuthStrategy;

export const IAuthXApiStrategyServiceKey: InjectionKey<IAuthXApiStrategyService> = Symbol('IAuthXApiStrategyService');
export const IAuthBearerStrategyServiceKey: InjectionKey<IAuthBearerStrategyService> = Symbol('IAuthBearerStrategyService');
