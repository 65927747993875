export module EmojiUtils {
  export const modifiers = [
    '\u{1f3fb}', // skin type 1-2
    '\u{1f3fc}', // skin type 3
    '\u{1f3fd}', // skin type 4
    '\u{1f3fe}', // skin type 5
    '\u{1f3ff}', // skin type 6
  ];

  export function removeModifier(emoji: string) {
    var ret = emoji;
    for(let i = 0; i < modifiers.length; i++) {
      ret = ret.replace(modifiers[i],'');
    }
    return ret;
  }
}