import { DIVIDERS_REGEXP } from "../regexp/dividers";
import { IEmojiSplitterService } from "./emojiSplitterService.interfaces";
import { injectable } from "inversify";

@injectable()
export class EmojiSplitterService implements IEmojiSplitterService {
  splitConvertedEmoji(text: string): string[] {
    const replacedEmojis = text.replaceAll(DIVIDERS_REGEXP, '<divider>');
    const splittedEmojis = replacedEmojis.split('<divider>');

    return splittedEmojis.filter((emoji) => emoji.length > 0);
  }
}