import { IEmojiService } from "./emojiService.interfaces";
import { injectable } from 'inversify'

@injectable()
export class EmojiService implements IEmojiService {
  getEmojiName(emoji: string): string | undefined {
    switch (emoji) {
      case '\uD83D\uDD25': return 'fire';
      case '\u2747':       return 'sparkle';
      case '\uD83D\uDE97': return 'automobile';
      case '\uD83D\uDCAF': return 'hundred-points';
      case '\uD83D\uDCB0': return 'money-bag';
      case '\uD83D\uDD1D': return 'top-arrow';
      case '\uD83C\uDF1F': return 'glowing-star';
      case '\uD83C\uDF89': return 'party-popper';
      case '\uD83D\uDE4F': return 'folded-hands';
      case '\uD83D\uDE07': return 'smiling-face-with-halo';
      case '\uD83D\uDE0D': return 'smiling-face-with-heart-eyes';
      case '\uD83E\uDD70': return 'smiling-face-with-hearts';
      case '\uD83D\uDCAA': return 'flexed-biceps';
      case '\uD83D\uDC4D': return 'thumbs-up_light-skin-tone';
      case '\uD83D\uDC84': return 'lipstick';
      case '\uD83E\uDDB7': return 'tooth';
      case '\uD83D\uDC68': return 'man-technologist';
      case '\uD83D\uDC69': return 'woman-technologist';
      case '\uD83E\uDDF6': return 'yarn';
      case '\uD83E\uDDE5': return 'coat';
      case '\uD83E\uDD7C': return 'lab-coat';
      case '\uD83E\uDDF5': return 'thread';
      case '\uD83D\uDC5E': return 'mans-shoe';
      case '\uD83E\uDE74': return 'thong-sandal';
      case '\uD83D\uDC5F': return 'running-shoe';
      case '\uD83C\uDFA9': return 'top-hat';
      case '\uD83E\uDE96': return 'military-helmet';
      case '\uD83D\uDCBC': return 'briefcase';
      case '\uD83D\uDC8D': return 'ring';
      case '\uD83C\uDF92': return 'backpack';
      case '\uD83E\uDDF3': return 'luggage';
      case '\uD83D\uDD76': return 'sunglasses';
      case '\u2600':
      case '\u2600\uFE0F': return 'sun';
      case '\uD83C\uDF4F': return 'green-apple';
      case '\uD83C\uDF4E': return 'red-apple';
      case '\uD83C\uDF4A': return 'tangerine';
      case '\uD83C\uDF4B': return 'lemon';
      case '\uD83C\uDF53': return 'strawberry';
      case '\uD83C\uDF47': return 'grapes';
      case '\uD83C\uDF49': return 'watermelon';
      case '\uD83C\uDF4C': return 'banana';
      case '\uD83E\uDD65': return 'coconut';
      case '\uD83C\uDF51': return 'peach';
      case '\uD83E\uDD51': return 'avocado';
      case '\uD83C\uDF3D': return 'ear-of-corn';
      case '\uD83C\uDF6D': return 'lollipop';
      case '\uD83C\uDF82': return 'birthday-cake';
      case '\uD83C\uDF6B': return 'chocolate-bar';
      case '\uD83E\uDDC1': return 'cupcake';
      case '\uD83C\uDF70': return 'shortcake';
      case '\u26BD':       return 'soccer-ball';
      case '\u{1F3C0}':    return 'basketball';
      case '\u{1F3C8}':    return 'american-football';
      case '\u{1F3BE}':    return 'tennis';
      case '\u{1F3D0}':    return 'volleyball';
      case '\u{1F3B1}':    return 'pool-8-ball';
      case '\u{1F3D3}':    return 'ping-pong';
      case '\uD83E\uDD4A': return 'boxing-glove';
      case '\uD83E\uDD4B': return 'martial-arts-uniform';
      case '\u{26F8}':     return 'ice-skate';
      case '\uD83E\uDD41': return 'drum';
      case '\u{265F}':     return 'chess-pawn';
      case '\uD83C\uDFAE': return 'video-game';
      case '\uD83D\uDE95': return 'taxi';
      case '\uD83D\uDE99': return 'sport-utility-vehicle';
      case '\uD83D\uDE8C': return 'bus';
      case '\uD83D\uDE8E': return 'trolleybus';
      case '\uD83D\uDDA5': return 'desktop-computer';
      case '\u{231A}':     return 'watch';
      case '\uD83D\uDCF1': return 'mobile-phone';
      case '\uD83D\uDDA8': return 'printer';
      case '\uD83D\uDCBB': return 'laptop';
      case '\u{2328}':     return 'keyboard';
      case '\uD83D\uDCFA': return 'television';
      case '\uD83C\uDF99': return 'studio-microphone';
      case '\u{2692}':     return 'hammer-and-pick';
      case '\uD83C\uDF81': return 'wrapped-gift';
      case '\uD83C\uDDE9\uD83C\uDDEA': return 'flag-germany'; 
      case '\uD83C\uDDF0\uD83C\uDDF7': return 'flag-south-korea';
      case '\uD83C\uDDF7\uD83C\uDDFA': return 'flag-russia';
      case '\uD83C\uDDFA\uD83C\uDDF8': return 'flag-united-states';
      case '\uD83C\uDDF9\uD83C\uDDF7': return 'flag-turkey';
      case '\uD83C\uDDEF\uD83C\uDDF5': return 'flag-japan';
      case '\uD83C\uDDE8\uD83C\uDDF3': return 'flag-china';
      case '\uD83C\uDDEE\uD83C\uDDF9': return 'flag-italy';
      case '\uD83C\uDDEB\uD83C\uDDF7': return 'flag-france';
      case '\uD83C\uDDE8\uD83C\uDDED': return 'flag-switzerland';
      default: return undefined;
    }
  }
}