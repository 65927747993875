import { injectable, inject } from 'inversify';
import { IAuthService } from '~/modules/auth/services/authService.interface';
import { UserData } from '~/modules/auth/types/userData';
import { IApiEndpointService, IApiEndpointServiceKey } from '~/endpoints/services/apiEndpointService.interface';
import { TokenData } from '~/modules/auth/types/tokenData';

@injectable()
export class AuthService implements IAuthService<TokenData> {
  constructor(
    @inject(IApiEndpointServiceKey as symbol) private api: IApiEndpointService,
  ) {}

  async loginUser(data: UserData): Promise<TokenData> {
    const response = await this.api.post(
      '/api/auth/login',
      data
    );
    return response.data;
  }
}
