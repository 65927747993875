import { IContainerBinder } from "~/configuration/inversify/containerAccessor.interface";
import { IEmojiConverterServiceKey } from "../../services/emojiConverterService.interfaces";
import { IEmojiServiceKey } from "../../services/emojiService.interfaces";
import { IEmojiSplitterServiceKey } from "../../services/emojiSplitterService.interfaces";
import { EmojiConverterService } from "../../services/emojiConverterService";
import { EmojiService } from "../../services/emojiService";
import { EmojiSplitterService } from "../../services/emojiSplitterService";

export function bindEmojiServices(bind: IContainerBinder) {
  bind(IEmojiConverterServiceKey).to(EmojiConverterService);
  bind(IEmojiServiceKey).to(EmojiService);
  bind(IEmojiSplitterServiceKey).to(EmojiSplitterService);
}