import { IContainerBinder } from "~/configuration/inversify/containerAccessor.interface";
import { INavBarServiceKey } from "../../services/navBarService.interfaces";
import { NavBarService } from "../../services/navBarService";
import { IAnchorScrollServiceKey } from "../../services/anchorScrollService.interfaces";
import { AnchorScrollService } from "../../services/anchorScrollService";

export function bindNavBar(bind: IContainerBinder) {
  bind(INavBarServiceKey).to(NavBarService);
  bind(IAnchorScrollServiceKey).to(AnchorScrollService);
}
