import { isObject } from 'lodash-es';
import { injectable } from 'inversify';
import { FieldDescription } from '../types/field-description';
import { FieldMargin } from '../types/field-margin';
import { FieldValue, FieldValueObject } from '../types/field-value';
import { IFieldListGuard } from './fieldListGuard.interfaces';

@injectable()
export class FieldListGuard implements IFieldListGuard {
  isFieldMargin(value: string | FieldMargin | undefined): value is FieldMargin {
    return isObject(value);
  }

  isFieldDescription(value: unknown): value is FieldDescription {
    return isObject(value) && 'value' in value;
  }

  isFieldValueObject(value: FieldValue): value is FieldValueObject {
    return isObject(value) && 'text' in value;
  }
}
